@define-mixin desk-xl {
    @media only screen (min-width: 1921px and max-width: 2561px) {
    @mixin-content;
}
}

@define-mixin desk-lg {
    @media (min-width: 1819px) {
        @mixin-content;
    }
}

@define-mixin desk-md {
    @media only screen and (min-width: 1619px) {
        @mixin-content;
    }
}

@define-mixin desk-sm {
    @media only screen and (max-width: 1281px) {
        @mixin-content;
    }
}

@define-mixin tab {
    @media only screen and (max-width: 1025px) {
        @mixin-content;
    }
}

@define-mixin tab-md {
    @media only screen and (max-width: 901px) {
        @mixin-content;
    }
}

@define-mixin tab-sm {
    @media only screen and (max-width: 769px) {
        @mixin-content;
    }
}

@define-mixin mob-xl {
    @media only screen and (max-width: 651px) {
        @mixin-content;
    }
}

@define-mixin mob-lg {
    @media only screen and (max-width: 551px) {
        @mixin-content;
    }
}

@define-mixin mob {
    @media only screen and (max-width: 431px) {
        @mixin-content;
    }
}

@define-mixin mob {
    @media only screen and (max-width: 361px) {
        @mixin-content;
    }
}
