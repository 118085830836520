#global-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    transition-duration: .15s;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    backdrop-filter: blur(8px);
    opacity: 0;
    pointer-events: none;
    background-color: rgba(31,41,55,.4);

    [role="status"] {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        inset: 0;

        svg {
            display: inline;
            width: 3rem;
            height: 3rem;
            fill: white;
            color: color-mod(white a(40%));
            animation: spin 1s linear infinite;

            @keyframes spin {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(360deg);
                }
            }
        }
    }

    span {
        clip: rect(0,0,0,0);
        border-width: 0;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
}

.disabled-btn-loader {
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: none;
    position: relative;

    [role="status"] {
        position: absolute;
        inset: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            display: inline;
            width: 1.5rem;
            height: 1.5rem;
            fill: white;
            color: color-mod(white a(40%));
            animation: spin 1s linear infinite;

            @keyframes spin {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(360deg);
                }
            }
        }
    }

    .disabled-btn-content {
        display: flex;
        opacity: 0;
    }

    .disabled-btn-sr-only {
        clip: rect(0,0,0,0);
        border-width: 0;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
}